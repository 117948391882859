export default class CustomerEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#customer_uuid") ? (elem.querySelector("#customer_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#customer_name") as HTMLInputElement).value,
            mail_domain: (elem.querySelector("#customer_mail_domain") as HTMLInputElement).value,
            info_email:  (elem.querySelector("#customer_info_email") as HTMLInputElement).value,
            active: (elem.querySelector("#customer_active") as HTMLInputElement).checked,
            internal: (elem.querySelector("#customer_internal") as HTMLInputElement).checked,
        }
    }
}